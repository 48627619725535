import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-ok-cancel-modal',
  templateUrl: './ok-cancel-modal.component.html',
  styleUrls: ['./ok-cancel-modal.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class OkCancelModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  @Input() title: string;
  @Input() text: string;
  @Input() textParams: any;
}
